import AuthForgottenPasswordConnect from "../auth/AuthForgottenPassword.Connect"
import AuthLoginAdminConnect from "../auth/AuthLoginAdmin.Connect"
import AuthLoginManagerConnect from "../auth/AuthLoginManager.Connect"
import AuthNewAccountPasswordConnect from "../auth/AuthNewAccountPassword.Connect"
import AuthResetPasswordConnect from "../auth/AuthResetPassword/AuthResetPassword.Connect"
import { FaqComponent } from "../portal/manager/faq/Faq.Component"

export const nonAuthRoutes = [
  { path: "/signin", component: AuthLoginManagerConnect, exact: true },
  { path: "/signin/admin", component: AuthLoginAdminConnect, exact: true },
  { path: "/reset-password", component: AuthForgottenPasswordConnect },
  { path: "/change-password", component: AuthResetPasswordConnect },
  { path: "/session/help/new-account-password", component: AuthNewAccountPasswordConnect },
  { path: "/faq", component: FaqComponent },
]
